import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../Header/Header";
import Auth from "../Auth/Auth";
import Main from "../Main/Main";
import PatientPage from "../PatientPage/PatientPage";
import Popup from "../Popup/Popup";
import "./App.css";
import mainApi from "../../assets/api/MainApi";
import Preloader from '../Preloader/Preloader'




function App() {



  const [isLoggedIn, setLoggedIn] = useState(undefined);
  const [currentUser, setCurrentUser] = useState(undefined)
  const [isPopupOpen, setPopupOpen] = useState(false);

  const [patients, setPatients] = useState(undefined)

  function logout() {
    console.log("logout")


    mainApi.logout()
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setLoggedIn(false)
        setPopupOpen(false)
      })

  }

  useEffect(() => {
    // console.log(moment().format('x'))
    mainApi.getMe()
      .then((res) => {
        setCurrentUser(res)
        setLoggedIn(true)
        console.log(res)
      })
      .catch((err) => {
        setLoggedIn(false)
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (currentUser) {
      mainApi.getPatients({ limit: 20 })
        .then((res) => {
          console.log(res.patients)
          setPatients(res.patients)
        })
        .catch((err) => {
          setPatients([])
          console.log(err)
        })
    }
  }, [currentUser])

  return (
    <div className="app">
      {isLoggedIn === undefined ?
        <div className='app__preloader'>
          <Preloader />

        </div>

        : <></>}


      {isLoggedIn ? <Header {...{ setPopupOpen }} /> : null}

      {isLoggedIn ?
        <Routes>
          <Route
            path="/login"
            element={<Auth {...{ isLoggedIn, setLoggedIn, setCurrentUser }} />}
          />
          <Route exact path="/" element={<Main {...{ patients, setPatients }} />} />
          <Route path="/patients/:patientId" element={<PatientPage />} />
          <Route path="/patients/:patientId/:type" element={<PatientPage />} />
          <Route path="/patients/:patientId/:type/:medicine_id" element={<PatientPage />} />

        </Routes>
        : isLoggedIn !== undefined ?
          <Routes>
            <Route
              path="*"
              element={<Auth {...{ isLoggedIn, setLoggedIn, setCurrentUser }} />}
            />
          </Routes>
          :
          <></>
      }

      <Popup popup="logout" {...{ isPopupOpen, setPopupOpen }} onClick={logout}>
        <p className="popup__title">
          Are you sure you want
          <br />
          to log out?
        </p>
      </Popup>
    </div>
  );
}

export default App;
