import { useEffect, useRef, useState } from "react";
import MedicationPopup from "../MedicationPopup/MedicationPopup";
import { PATIENT_LINK_ICON } from "../../../assets/utils/icons";

import pillIcon from '../../../assets/images/main/med_type/pill.png'
import injectionIcon from '../../../assets/images/main/med_type/injection.png'
import syrupIcon from '../../../assets/images/main/med_type/syrup.png'
import sprayIcon from '../../../assets/images/main/med_type/spray.png'
import tabletIcon from '../../../assets/images/main/med_type/tablet.png'
import dropsIcon from '../../../assets/images/main/med_type/drops.png'
import procedureIcon from '../../../assets/images/main/med_type/procedure.png'
import ointmentIcon from '../../../assets/images/main/med_type/ointment.png'


import "./MedicationHistory.css";
import mainApi from "../../../assets/api/MainApi";

function MedicationHistory({ data, setTypeInfo, currentMedication, discontinuedMedication, patientId, setCurrentMedication, setDiscontinuedMedication }) {
  const [isPopupOpen, setPopupOpen] = useState({});
  const [medicine, setMedicine] = useState({});
  const [indexedIntakes, setIndexedIntakes] = useState(undefined)

  function openPopup(medicine) {
    mainApi.getMedicineInfoStatic({ medication_id: medicine._id, user_id: patientId })
      .then((res) => {
        console.log(res.medicine)
        mainApi.getMedicineInfoIndexed({ medication_id: medicine._id, user_id: patientId, limit: 15 })
          .then((res2) => {
            console.log(res2.medicine_intakes)
            setIndexedIntakes(res2.medicine_intakes)
            setPopupOpen({ ...isPopupOpen, [res.medicine._id]: true });
          })
          .catch((err) => {
            setPopupOpen({ ...isPopupOpen, [res.medicine._id]: true });
            console.log(err)
          })

        setMedicine(res.medicine);
      })
      .catch((err) => {
        console.log(err)
      })

  }

  function closePopup() {
    setPopupOpen({ ...isPopupOpen, [medicine._id]: false });
  }


  const listCONTINUEDInnerRef = useRef();
  const listDISCONTINUEDInnerRef = useRef();

  const [pageCONTINUEDValue, setPageCONTINUEDValue] = useState(0);
  const [prevCONTINUEDScrollPosition, setPrevCONTINUEDScrollPosition] = useState(-1);

  const [pageDISCONTINUEDValue, setPageDISCONTINUEDValue] = useState(0);
  const [prevDISCONTINUEDScrollPosition, setPrevDISCONTINUEDScrollPosition] = useState(-1);


  const [scrollPosition, setScrollPosition] = useState(0);
  // const [isPreloaderVisible, setPreloaderVisible] = useState(false)
  const [scrollTraking, setScrollTraking] = useState(true);
  const handleScroll = () => {
    const position = window.pageYOffset;

    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {

    if (listCONTINUEDInnerRef.current && scrollTraking && scrollPosition > prevCONTINUEDScrollPosition && currentMedication && currentMedication.length > 0) {
      setPrevCONTINUEDScrollPosition(scrollPosition)
      const { scrollHeight } = listCONTINUEDInnerRef.current;
      if (scrollHeight < scrollPosition + 600) {
        setScrollTraking(false)
        setPageCONTINUEDValue(pageCONTINUEDValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevCONTINUEDScrollPosition, pageCONTINUEDValue, currentMedication]);

  useEffect(() => {

    if (pageCONTINUEDValue > 0 && currentMedication && currentMedication.length === 15 * pageCONTINUEDValue) {
      let prevId = currentMedication[currentMedication.length - 1]._id
      console.log(prevId)
      console.log('ss')
      mainApi.getPatientMedicationHistory({ user_id: patientId, limit: 15, still_taking: true, last_id: prevId })
        .then((res) => {
          console.log(res.medicines)
          setCurrentMedication(prewList => prewList.concat(res.medicines))
        })
        .catch((err) => {
          console.log(err)
        })
      // mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, last_id: prevId  })
      //   .then((res) => {
      //     console.log(res.intakes)
      //     setIndexedIntakeData(prewList => prewList.concat(res.intakes))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCONTINUEDValue, currentMedication])



  useEffect(() => {

    if (listDISCONTINUEDInnerRef.current && scrollTraking && scrollPosition > prevDISCONTINUEDScrollPosition && discontinuedMedication && discontinuedMedication.length > 0) {
      setPrevDISCONTINUEDScrollPosition(scrollPosition)
      const { scrollHeight } = listDISCONTINUEDInnerRef.current;
      console.log(scrollHeight, scrollPosition, scrollHeight < scrollPosition + 800)
      if (scrollHeight < scrollPosition + 800) {
        setScrollTraking(false)
        setPageDISCONTINUEDValue(pageDISCONTINUEDValue + 1)
        setTimeout(() => {
          setScrollTraking(true)
        }, 500);
      }
    }
  }, [scrollPosition, scrollTraking, prevDISCONTINUEDScrollPosition, pageDISCONTINUEDValue, discontinuedMedication]);

  useEffect(() => {

    if (pageDISCONTINUEDValue > 0 && discontinuedMedication && discontinuedMedication.length === 15 * pageDISCONTINUEDValue) {
      let last_id = discontinuedMedication[discontinuedMedication.length - 1]._id
    
      console.log(last_id)
      console.log('ss')
      mainApi.getPatientMedicationHistory({ user_id: patientId, limit: 15, still_taking: false, last_id: last_id })
        .then((res) => {
          console.log(res.medicines)
          setDiscontinuedMedication(prewList => prewList.concat(res.medicines))
        })
        .catch((err) => {
          console.log(err)
        })
      // mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, last_id: prevId  })
      //   .then((res) => {
      //     console.log(res.intakes)
      //     setIndexedIntakeData(prewList => prewList.concat(res.intakes))
      //   })
      //   .catch((err) => {
      //     console.log(err)
      //   })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageDISCONTINUEDValue, discontinuedMedication])

  const [isCurrentVisible, setCurrentVisible] = useState(true)
  const [isDiscontinuedVisible, setDiscontinuedVisible] = useState(false)

  return (
    <div className="history">
      <div className="history__medicine-box">
        <div className="history__heading" onClick={() => {
          setCurrentVisible(!isCurrentVisible)
        }}>
          <p className="history__title">Current medication</p>
          <svg className={`history__heading-arrow ${!isCurrentVisible ? 'history__heading-arrow_rotated' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M12 16L18 10.332L16.59 9L12 13.3266L7.41 9L6 10.332L12 16Z" fill="#323232" />
          </svg>
        </div>
        {isCurrentVisible ?
          <ul className="history__cards-list" ref={listCONTINUEDInnerRef}>
            {currentMedication && currentMedication.length !== 0
              ? currentMedication.map((medicine) => (
                <li className="history__medicine-card" key={medicine._id}>
                  <div className="history__medicine-icon-box">
                    {medicine.type === 'pill' && <img className="history__medicine-icon" src={pillIcon} alt='' />}
                    {medicine.type === 'injection' && <img className="history__medicine-icon" src={injectionIcon} alt='' />}
                    {medicine.type === 'syrup' && <img className="history__medicine-icon" src={syrupIcon} alt='' />}
                    {medicine.type === 'spray' && <img className="history__medicine-icon" src={sprayIcon} alt='' />}
                    {medicine.type === 'tablet' && <img className="history__medicine-icon" src={tabletIcon} alt='' />}
                    {medicine.type === 'drops' && <img className="history__medicine-icon" src={dropsIcon} alt='' />}
                    {medicine.type === 'ointment' && <img className="history__medicine-icon" src={ointmentIcon} alt='' />}
                    {medicine.type === 'procedure' && <img className="history__medicine-icon" src={procedureIcon} alt='' />}
                  </div>
                  <div className="history__medicine-info-box">
                    <p className="history__medicine-name">{medicine.name}</p>
                    <div className="history__info-box">
                      <p className="history__info-title">Dosage</p>
                      <p className="history__info-text">{medicine.dosage} {medicine.dosage_type}</p>
                    </div>
                    <div className="history__info-box">
                      <p className="history__info-title">Frequency</p>
                      <p className="history__info-text">
                        {medicine.frequency === 'one_time_intake' ? 'One time intake' : ''}
                        {medicine.frequency === 'everyday' ? 'Everyday' : ''}
                        {medicine.frequency === 'specific_days' ? 'Specific days' : ''}
                        {!medicine.frequency ? 'Unknown' : ''}
                      </p>
                    </div>
                  </div>
                  <button
                    className="history__open-button"
                    type="button"
                    aria-label="Open medication popup"
                    onClick={() => openPopup(medicine)}
                  >
                    {PATIENT_LINK_ICON}
                  </button>
                </li>
              ))
              : null}
          </ul>
          : <></>}

      </div>

      <div className="history__medicine-box">

        <div className="history__heading" onClick={() => {
          setDiscontinuedVisible(!isDiscontinuedVisible)
        }}>
          <p className="history__title">Discontinued</p>
          <svg className={`history__heading-arrow ${!isDiscontinuedVisible ? 'history__heading-arrow_rotated' : ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M12 16L18 10.332L16.59 9L12 13.3266L7.41 9L6 10.332L12 16Z" fill="#323232" />
          </svg>
        </div>
        {isDiscontinuedVisible ?
          <ul className="history__cards-list" ref={listDISCONTINUEDInnerRef}>
            {discontinuedMedication && discontinuedMedication.length !== 0
              ? discontinuedMedication.map((medicine) => (
                <li
                  className="history__medicine-card history__medicine-card_discontinued"
                  key={medicine._id}
                >
                  <div className="history__medicine-icon-box">
                    {medicine.type === 'pill' && <img className="history__medicine-icon" src={pillIcon} alt='' />}
                    {medicine.type === 'injection' && <img className="history__medicine-icon" src={injectionIcon} alt='' />}
                    {medicine.type === 'syrup' && <img className="history__medicine-icon" src={syrupIcon} alt='' />}
                    {medicine.type === 'spray' && <img className="history__medicine-icon" src={sprayIcon} alt='' />}
                    {medicine.type === 'tablet' && <img className="history__medicine-icon" src={tabletIcon} alt='' />}
                    {medicine.type === 'drops' && <img className="history__medicine-icon" src={dropsIcon} alt='' />}
                    {medicine.type === 'ointment' && <img className="history__medicine-icon" src={ointmentIcon} alt='' />}
                    {medicine.type === 'procedure' && <img className="history__medicine-icon" src={procedureIcon} alt='' />}
                  </div>
                  <div className="history__medicine-info-box">
                    <p className="history__medicine-name" title={medicine.name}>{medicine.name}</p>
                    <div className="history__info-box">
                      <p className="history__info-title">Dosage</p>
                      <p className="history__info-text">{medicine.dosage} {medicine.dosage_type}</p>
                    </div>
                    <div className="history__info-box">
                      <p className="history__info-title">Frequency</p>
                      <p className="history__info-text">
                        {medicine.frequency === 'one_time_intake' ? 'One time intake' : ''}
                        {medicine.frequency === 'everyday' ? 'Everyday' : ''}
                        {medicine.frequency === 'specific_days' ? 'Specific days' : ''}
                        {!medicine.frequency ? 'Unknown' : ''}
                      </p>
                    </div>
                  </div>
                  <button
                    className="history__open-button"
                    type="button"
                    aria-label="Open medication popup"
                    onClick={() => openPopup(medicine)}
                  >
                    {PATIENT_LINK_ICON}
                  </button>
                </li>
              ))
              : null}
          </ul>
          : <></>}

      </div>

      <MedicationPopup
        isOpen={isPopupOpen[medicine._id]}
        {...{ medicine, setTypeInfo, setPopupOpen, closePopup, patientId, indexedIntakes, setIndexedIntakes }}
      />
    </div>
  );
}

export default MedicationHistory;
