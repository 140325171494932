import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import MedicationIntakes from "./MedicationIntakes/MedicationIntakes";
import MedicationHistory from "./MedicationHistory/MedicationHistory";
import {
  LEFT_PART_BUTTON_ICON,
  RIGHT_PART_BUTTON_ICON,
} from "../../assets/utils/icons";

import "./PatientPage.css";
import mainApi from "../../assets/api/MainApi";
import MedicalData from "./MedicalData/MedicalData";
import Notes from "./Notes/Notes";
import Preloader from "../Preloader/Preloader";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import { formatDataFromApi } from "../../assets/utils/utils";

function PatientPage() {
  const navigate = useNavigate()

  const { patientId, type, medicine_id } = useParams();


  const [typeInfo, setTypeInfo] = useState(type ? type : "medicalData");
  const [userAiID, setUserAiID] = useState('')

  const [userMedicalData, setUserMedicalData] = useState(undefined)
  const [staticIntakesData, setStaticIntakesData] = useState(undefined)
  const [indexedIntakesData, setIndexedIntakeData] = useState(undefined)
  const [currentMedication, setCurrentMedication] = useState(undefined)
  const [discontinuedMedication, setDiscontinuedMedication] = useState(undefined)

  const [isPreloaderVisible, setPreloaderVisible] = useState(false)

  const [notes, setNotes] = useState(undefined)

  useEffect(() => {
    if (type) {
      if (['medicalData', 'intakes', 'history', 'notes'].indexOf(type) === -1) {
        navigate(`/patients/${patientId}/medicalData`)
        setTypeInfo('medicalData')
      } else {
        setTypeInfo(type)
      }

    }
    else {
      // navigate(`/patients/${patientId}/medicalData`)
      // setTypeInfo('medicalData')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, patientId])

  const [userSyndromes, setUserSyndromes] = useState(undefined)
  const [userSymptomes, setUserSymptomes] = useState(undefined)

  useEffect(() => {
    if (patientId && typeInfo) {
      console.log(typeInfo)
      setPreloaderVisible(true)
      switch (typeInfo) {
        case 'medicalData':
          mainApi.getPatientMedicalData({ user_id: patientId })
            .then((res) => {
              console.log(res)
              setUserAiID(res.ai_id)
              setUserMedicalData(res)
              mainApi.getPatientMedicalDataSyndromes({ user_id: patientId, limit: 10 })
                .then((res_sundromes) => {
                  console.log(res_sundromes.user_syndromes)
                  setUserSyndromes(formatDataFromApi({ textKey: 'syndrome_text', cardsToFormat: res_sundromes.user_syndromes, objKey: 'syndromes_id', startValue: [], cardKey: 'syndrome' }))
                  mainApi.getPatientMedicalDataSymptomes({ user_id: patientId, limit: 10 })
                    .then((res_symtomes) => {
                      setUserSymptomes(formatDataFromApi({ textKey: 'symptome_text', cardsToFormat: res_symtomes.user_symptoms, objKey: 'symptoms_id', startValue: [], cardKey: 'symptom' }))
                    })
                    .catch((err) => {
                      console.log(err)
                    })
                })
                .catch((err) => {
                  console.log(err)
                })
            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              setPreloaderVisible(false)
            })
          break;

        case 'intakes':
          mainApi.getPatientMedicalIntakesStatic({ user_id: patientId })
            .then((res) => {
              console.log(res)
              setStaticIntakesData(res)
              setUserAiID(res.user.ai_id)
              mainApi.getPatientMedicalIntakesIndexed({ user_id: patientId, limit: 20, medicine_id: medicine_id })
                .then((res) => {
                  console.log(res.intakes)
                  setIndexedIntakeData(res.intakes)
                })
                .catch((err) => {
                  console.log(err)
                })
                .finally(() => {
                  setPreloaderVisible(false)
                })

            })
            .catch((err) => {
              console.log(err)
            })
            .finally(() => {
              setPreloaderVisible(false)
            })
          break;

        case 'history':
          mainApi.getPatientMedicalData({ user_id: patientId })
            .then((res3) => {
              console.log(res3)
              setUserAiID(res3.ai_id)
              setUserMedicalData(res3)
              mainApi.getPatientMedicationHistory({ user_id: patientId, limit: 15, still_taking: true })
                .then((res) => {
                  console.log(res.medicines)
                  setCurrentMedication(res.medicines)
                  setTimeout(() => {
                    mainApi.getPatientMedicationHistory({ user_id: patientId, limit: 15, still_taking: false })
                      .then((res2) => {
                        console.log(res2.medicines)
                        setDiscontinuedMedication(res2.medicines)

                      })
                      .catch((err) => {
                        console.log(err)
                      })
                      .finally(() => {
                        setPreloaderVisible(false)
                      })
                  }, 600);

                })
                .catch((err) => {
                  console.log(err)
                })
            })
            .catch((err) => {
              console.log(err)
            })


          break;

        case 'notes':
          mainApi.getNotes({ user_id: patientId, limit: 12 })
            .then((res) => {
              console.log(res.notes)
              setNotes(res.notes)
              mainApi.getPatientMedicalData({ user_id: patientId })
                .then((res3) => {
                  console.log(res3)
                  setUserAiID(res3.ai_id)
                  setUserMedicalData(res3)
                })
                .catch((err) => {
                  console.log(err)
                })
                .finally(() => {
                  setPreloaderVisible(false)
                })
            })
            .catch((err) => {
              console.log(err)
            })

          break;

        default:
          break;
      }


    }

    // setData(patientDetailsData);
  }, [patientId, typeInfo, medicine_id]);

  return (
    <section className="patient">
      <div className="patient__title-box">
        <p className="patient__title-text">Patient ID:</p>
        {userAiID ? <p className="patient__id-text">{userAiID}</p> : <></>}
        {!userAiID && isPreloaderVisible ?
          <div className="patient__id-preloader">
            <MiniPreloader isLinkColor={false} />
          </div>
          : <></>}
      </div>

      <div className="patient__profile">
        <div className="patient__profile-nav">
          <Link className={`patient__nav-button patient__nav-button_type_medicalData ${typeInfo === "medicalData" ? "patient__nav-button_active" : ""
            }`} to={`/patients/${patientId}/medicalData`}
            onClick={() => {
              setPreloaderVisible(true)
              setTypeInfo("medicalData")
            }}
          >
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medical Data</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>


          <Link className={`patient__nav-button patient__nav-button_type_intakes ${typeInfo === "intakes" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/intakes`}
            onClick={() => {
              setPreloaderVisible(true)
              setTypeInfo("intakes")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medication Intakes</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <Link className={`patient__nav-button patient__nav-button_type_history ${typeInfo === "history" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/history`}
            onClick={() => {
              setPreloaderVisible(true)
              setTypeInfo("history")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Medication History</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <Link className={`patient__nav-button patient__nav-button_type_notes ${typeInfo === "notes" ? "patient__nav-button_active" : ""
            }`}
            to={`/patients/${patientId}/notes`}
            onClick={() => {
              setPreloaderVisible(true)
              setTypeInfo("notes")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Notes</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </Link>

          <button
            className={`patient__nav-button patient__nav-button_type_analysis patient__nav-button_type_analysis_disabled ${typeInfo === "analysis" ? "patient__nav-button_active" : ""
              }`}
            type="button"
            onClick={() => {
              setPreloaderVisible(true)
              setTypeInfo("analysis")
            }}
          >
            {LEFT_PART_BUTTON_ICON}
            <div className="patient__btn-patient">
              <p className="patient__nav-button-text">Analysis</p>
            </div>
            {RIGHT_PART_BUTTON_ICON}
          </button>
        </div>

        <div className="patient__profile-content">
          {isPreloaderVisible ?
            <div className="patient__profile-preloader">
              <Preloader />
            </div> :
            <>
              {typeInfo === "medicalData" ? (
                <MedicalData data={userMedicalData} setUserSyndromes={setUserSyndromes} userSyndromes={userSyndromes} userSymptomes={userSymptomes} patientId={patientId} />
              ) : null}

              {typeInfo === "intakes" ? (
                <MedicationIntakes medicine_id={medicine_id} patientId={patientId} setIndexedIntakeData={setIndexedIntakeData} staticData={staticIntakesData} indexedData={indexedIntakesData} />
              ) : null}

              {typeInfo === "history" ? (
                <MedicationHistory setDiscontinuedMedication={setDiscontinuedMedication} setCurrentMedication={setCurrentMedication} patientId={patientId} discontinuedMedication={discontinuedMedication} currentMedication={currentMedication} {...{ setTypeInfo }} />
              ) : null}

              {typeInfo === "notes" ? (
                <Notes patientId={patientId} {...{ notes, setNotes }} />
              ) : null}

              {typeInfo === "analysis" ? (
                <div className="patient__analysis"></div>
              ) : null}
            </>
          }

        </div>
      </div>
    </section>
  );
}

export default PatientPage;
