
export function formatDataFromApi({ startValue, cardsToFormat, objKey, cardKey, textKey }) {
    let formated = startValue
    let cards = cardsToFormat

    cards.forEach(item => {

        if (formated.length === 0) {
            formated = [{
                [objKey]: item[objKey],
                items: formatDataKeyValueType(item, cardKey),
                _id: item._id,
                name: item[cardKey].text
            }]

        } else if (formated.filter((time_table_item) => {
            if (time_table_item[objKey] === item[objKey]) return true
            else return false
        }).length > 0) {
            formated = formated.map((elem) => {
                if (elem[objKey] === item[objKey]) {
                    return {
                        ...elem,
                        items: [...elem.items, formatDataKeyValueType(item, cardKey)]
                    }
                } else return elem
            })
        } else {
            formated = [...formated, {
                [objKey]: item[objKey],
                items: formatDataKeyValueType(item, cardKey),
                _id: item._id,
                name: item[cardKey].text
            }]

        }

    });

    console.log(formated)
    return formated
}

function formatDataKeyValueType(card, cardKey) {
    let result = []
    card[cardKey].data.forEach((item) => {


        result = [...result, {
            name: item.name,
            key: item.key,
            value: card.data[item.key].value,
            type: item.type,
            default: item.default,
            choose_values: item.value
        }]



    })
    return result
}